<template>
  <form @submit.prevent="transfer">
    <modal-card title="Transfer contract">
      <b-message type="is-info">
        <p v-if="oldSite && newSite">
          This contract will be transferred from
          <strong>{{ oldSite.fqdn }}</strong> to
          <strong>{{ newSite.fqdn }}</strong>
        </p>
        <p v-else>
          Please select which site you'd like to transfer this contract to.
        </p>
      </b-message>

      <loading v-if="loading" />

      <no-results
        v-else-if="availableSites.length === 0"
        title="No available results"
        message="There are no available sites to show."
        style="margin: auto;"
      />

      <b-select
        v-else
        v-model="selectedId"
        :loading="loading"
        :disabled="processing || loading"
        placeholder="Select site"
        expanded
      >
        <option
          v-for="site in availableSites"
          :key="site._id"
          :value="site._id"
          :disabled="site.isSubscribed || site._id === siteId"
          :class="{ 'has-text-grey': site.isSubscribed }"
          >{{ site.fqdn }}</option
        >
      </b-select>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="availableSites.length === 0 || loading || processing"
        type="submit"
        class="button is-success"
      >
        Transfer
      </button>
    </modal-card>
  </form>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "TransferContractModal",
  props: {
    contractId: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sites: [],
      selectedId: null,
      processing: false,
      loading: true
    };
  },
  computed: {
    oldSite() {
      return this.$_.find(this.sites, site => site._id === this.siteId);
    },
    newSite() {
      return this.$_.find(this.sites, site => site._id === this.selectedId);
    },
    availableSites() {
      return this.$_(this.sites)
        .orderBy(["isSubscribed", "fqdn"], ["asc", "asc"])
        .value();
    }
  },
  created() {
    this.getSites();
  },
  methods: {
    transfer() {
      if (!this.selectedId || !this.newSite || !this.oldSite) return;
      this.processing = true;
      this.$store
        .dispatch("billing/transferContract", {
          contractId: this.contractId,
          oldSiteId: this.oldSite._id,
          newSiteId: this.newSite._id
        })
        .then(message => {
          this.processing = false;
          this.$toast.open({
            message
          });
          this.$emit("success", this.selectedId);
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: error,
            type: "is-danger"
          });
        });
    },
    getSites() {
      this.loading = true;
      let sitesRef = query(
        collection(this.$firestore, `sites`),
        where(`owners`, `array-contains`, this.userId)
      );
      getDocs(sitesRef)
        .then(sites => {
          sites.docs.forEach(site => {
            this.sites.push(this.$_.merge({}, { _id: site.id }, site.data()));
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
